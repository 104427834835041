import contact from './lib/contact'
import rebox from './lib/rebox'
import menuDescription from './lib/menu-description'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form', {focus: false})
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  scrollSpy('.header-nav-sub:visible', {offset: 0})
  smoothScroll('a[href^="#"]', {offset: 0})
  menuDescription({
    items: '.header-nav-sub-list li > a[data-description]',
    currentItem: '.header-nav-sub-list li.current > a[data-description]',
    description: '.header-nav-sub-description'
  })
})

$(document).ready(function () {
  $(document).scroll(function () {
    var scroll = $(this).scrollTop()
    if (scroll > 0) {
      $('.header').addClass('fixed');
    } else {
      $('.header').removeClass('fixed');
    }
  })
})
